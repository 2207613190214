<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>О версии</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="logo-box">
    <div>
      <img class="logo-pic" src="assets/rc.svg" />
    </div>
    <div class="logo-text">Rental Club</div>
  </div>
  <div style="padding: 20px">
    @if (currentVersion) {
      <div style="font-weight: bold">Версия: {{ currentVersion }}</div>
      <br />
    }
    <div style="font-weight: bold">Описание:</div>
    <div>
      <ol>
        <li>
          Запущен личный кабинет для стационарных компьютеров с полным функционалом мобильного
          приложения.
        </li>
        <br />
        <li>
          Отправка уведомлений поставщикам о заявке реализована отдельной кнопкой после создания
          заявки. Это дает вам возможность создавать заявку и искать технику вручную, без
          информирования всех подходящих поставщиков.
        </li>
        <br />
        <li>Улучшен общий алгоритм подбора поставщиков для выдачи в каталоге заявки.</li>
        <br />
        <li>Запущена бета-версия подписания заявки между заказчиком и поставщиком.</li>
        <br />
        <li>
          Обновлены и дополнены источники внешних заявок, на которые может откликнуться
          пользователь.
        </li>
        <br />
        <li>
          Разработан новый быстрый чат поддержки с возможностью обмена файлами и фотографиями.
        </li>
      </ol>
    </div>
  </div>
</ion-content>
