<ion-header>
  <ion-toolbar>
    <ion-buttons left>
      <button navPop ion-button class="back-button back-button-md">
        <ion-icon name="close" class="back-button-icon back-button-icon-md"></ion-icon>
      </button>
    </ion-buttons>
    <ion-title>Заявка на выезд</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content padding class="redesign">
  <ion-item class="entry-field-row entry-field item-custom" no-lines>
    <ion-textarea
      placeholder="Комментарий..."
      [value]="comment"
      class="comment"
      name="comment"
    ></ion-textarea>
  </ion-item>
  <p>Дата выезда</p>
  <ion-item class="entry-field-row entry-field">
    <ion-icon item-end name="md-calendar"></ion-icon>

    <ion-datetime
      #datetime
      (ionChange)="updateStartDateTime()"
      [value]="pickedDate"
      id="date"
      preferWheel="true"
      presentation="date-time"
      minuteValues="0,15,30,45"
      mode="ios"
      locale="ru-RU"
    ></ion-datetime>
  </ion-item>
</ion-content>
<ion-footer class="redesign">
  <ion-toolbar no-padding>
    <div class="footer-buttons vertical">
      <button
        [disabled]="!startDate"
        ion-button
        mode="ios"
        large
        class="button-secondary"
        color="danger"
        tappable
        (click)="close()"
      >
        <span class="footer-button-txt">Отправить заявку на выезд</span>
      </button>
    </div>
  </ion-toolbar>
</ion-footer>
