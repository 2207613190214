<ion-picker>
  <ion-picker-column [value]="selectedDay" (ionChange)="onDateChange($event)">
    @for (day of days; track day.value) {
      <ion-picker-column-option [value]="day.value" [disabled]="day.disabled">
        {{ day.label }}
      </ion-picker-column-option>
    }
  </ion-picker-column>
  <ion-picker-column
    [value]="selectedTime"
    [class.invisible]="selectedDay === NO_DAY"
    (ionChange)="onTimeChange($event)"
  >
    @for (time of times; track time.value) {
      <ion-picker-column-option [value]="time.value" [disabled]="time.disabled">
        {{ time.label }}
      </ion-picker-column-option>
    }
  </ion-picker-column>
</ion-picker>
<div class="okButton">
  <ion-button class="ion-margin-bottom" fill="clear" (click)="onSaveDateTimeButtonClick($event)">
    Готово
  </ion-button>
</div>
