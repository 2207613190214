import { EntryPaymentType, EntryWorkType } from '../../base-types.gql-gen';

export type AddressObjectUIData = {
  fullAddress: string;
  coords: number[];
  ownerOrganization: {
    id: string;
    name: string;
  };
  title: string;
};

export type AddressObjectGroupUIData = {
  title: string;
  address: AddressObjectUIData[];
};

export const createAddressObjectGroups = (
  addressObjects: AddressObjectUIData[],
  myOrganizationId: string,
) => {
  const addressObjectsMyWithTitle: AddressObjectUIData[] = [];
  const addressObjectsMyWithoutTitle: AddressObjectUIData[] = [];
  const addressObjectsOtherWithTitle = new Map<string, AddressObjectUIData[]>();
  for (const addrObj of addressObjects) {
    if (addrObj.ownerOrganization.id === myOrganizationId) {
      if (addrObj.title) {
        addressObjectsMyWithTitle.push(addrObj);
      } else {
        addressObjectsMyWithoutTitle.push(addrObj);
      }
    } else if (addrObj.title) {
      let list = addressObjectsOtherWithTitle.get(addrObj.ownerOrganization.id);
      if (!list) {
        list = [];
        addressObjectsOtherWithTitle.set(addrObj.ownerOrganization.id, list);
      }
      list.push(addrObj);
    }
  }
  const addressObjectGroups: AddressObjectGroupUIData[] = [];
  addressObjectGroups.push({
    title: 'Свои объекты',
    address: addressObjectsMyWithTitle,
  });
  for (const [, address] of addressObjectsOtherWithTitle) {
    addressObjectGroups.push({
      title: address[0].ownerOrganization.name,
      address: address,
    });
  }
  addressObjectGroups.push({
    title: 'Адреса',
    address: addressObjectsMyWithoutTitle,
  });
  return addressObjectGroups;
};

export type WorkUnitUIData = {
  name: EntryWorkType;
  ruName: string;
};

export type PaymentTypeUIData = {
  value: EntryPaymentType;
  label: string;
};

export const PAYMENT_TYPES: PaymentTypeUIData[] = [
  { value: 'none', label: 'Любой' },
  { value: 'cash', label: 'Наличные/карта' },
  { value: 'cashless_with_taxes', label: 'Б/Н с НДС' },
  { value: 'cashless', label: 'Б/Н без НДС' },
];
