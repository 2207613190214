import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonPicker,
  IonPickerColumn,
  IonPickerColumnOption,
  IonToolbar,
} from '@ionic/angular/standalone';
import dayjs from 'dayjs';

const DAYJS_ISO_WITH_UTC_OFFSET = 'YYYY-MM-DDTHH:mm:ssZ';

type OptionItem = {
  label: string;
  value: string;
  disabled?: boolean;
};

export type RcDateTimeChangeEvent = {
  date: string; // empty if no date
  time: string; // empty if no time
  datetime: string; // empty if neither no date nor time
};

@Component({
  selector: 'app-datetime-wheels-modal',
  standalone: true,
  imports: [
    IonPicker,
    IonPickerColumn,
    IonPickerColumnOption,
    IonButton,
    IonFooter,
    IonContent,
    IonToolbar,
    IonButtons,
  ],
  templateUrl: './datetime-wheels-modal.component.html',
  styleUrl: './datetime-wheels-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatetimeWheelsModalComponent implements OnInit {
  @Output() rcDateTimeChange = new EventEmitter<RcDateTimeChangeEvent>();

  protected readonly NO_DAY = 'no-day';
  protected readonly NO_TIME = 'no-time';

  protected days: OptionItem[] = [];
  protected selectedDay = '';
  protected times: OptionItem[] = [];
  protected selectedTime = '';

  ngOnInit(): void {
    const today = dayjs().set('ms', 0).set('s', 0).set('m', 0).set('h', 0);
    const tomorrow = today.add(1, 'd');
    this.selectedDay = today.format(DAYJS_ISO_WITH_UTC_OFFSET);
    this.selectedTime = this.NO_TIME;
    this.days.push({
      label: 'Дата обсуждаема',
      value: this.NO_DAY,
    });
    this.days.push({
      label: 'Сегодня',
      value: today.format(DAYJS_ISO_WITH_UTC_OFFSET),
    });
    this.days.push({
      label: 'Завтра',
      value: tomorrow.format(DAYJS_ISO_WITH_UTC_OFFSET),
    });
    for (let i = 0; i < 30; i += 1) {
      const d = tomorrow.add(i, 'd');
      this.days.push({
        label: d.format('dd, D MMMM'),
        value: d.format(DAYJS_ISO_WITH_UTC_OFFSET),
      });
    }
    this.updateTimes();
  }

  onDateChange(ev: CustomEvent) {
    ev.preventDefault();
    this.selectedDay = ev.detail.value;
    if (this.selectedDay === this.NO_DAY) {
      this.selectedTime = this.NO_TIME;
    }
    this.updateTimes();
  }

  onTimeChange(ev: CustomEvent) {
    ev.preventDefault();
    this.selectedTime = ev.detail.value;
  }

  private updateTimes() {
    this.times = [];
    const noTime = {
      label: 'Не выбрано',
      value: this.NO_TIME,
    };
    if (this.selectedDay === this.NO_DAY) {
      this.times.push(noTime);
      return;
    }
    let noTimeAdded = false;
    const day: dayjs.Dayjs = dayjs(this.selectedDay);
    const now = dayjs();
    for (let i = 0; i < 48; i += 1) {
      const d = day.add(i * 30, 'm');
      const df = d.format('HH:mm');
      if (!noTimeAdded && df > '08:30' && d > now) {
        this.times.push(noTime);
        noTimeAdded = true;
      }
      if (d > now) {
        this.times.push({
          disabled: d < now,
          label: d.format('HH:mm'),
          value: d.format(DAYJS_ISO_WITH_UTC_OFFSET),
        });
      }
    }
    if (!noTimeAdded) {
      this.times.push(noTime);
      // noTimeAdded = true;
    }
  }

  onSaveDateTimeButtonClick(ev: Event) {
    ev.preventDefault();
    let date = '';
    if (this.selectedDay !== this.NO_DAY) {
      const d = dayjs(this.selectedDay);
      date = d.format('YYYY-MM-DD');
    }
    let time = '';
    if (this.selectedTime !== this.NO_TIME) {
      const d = dayjs(this.selectedTime);
      time = d.format('HH:mm');
    }
    let datetime = '';
    if (this.selectedTime !== this.NO_TIME) {
      datetime = this.selectedTime;
    } else if (this.selectedDay !== this.NO_DAY) {
      datetime = this.selectedDay;
    }
    this.rcDateTimeChange.emit({ date, time, datetime });
  }
}
