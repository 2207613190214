<div>
  @if (machine) {
    @if (machine.photos.length) {
      <div class="machine-photo">
        @if (machine.verificationStatus === "approved" && machine.photos[0]) {
          <img src="{{ machine.photos[0].src }}?w=1000&h=1000" />
        }
      </div>
    }
    <div class="box-inner machine">
      <div class="machine-info" tappable (click)="showMachine()">
        <div class="machine-category-name">
          {{ category }}
          <span class="entry-main-param">{{ printMachineMainParamValue }}</span>
        </div>
        <div class="machine-name">
          {{ machine.model }}, {{ machine.year }}
          @if (machine.reg) {
            <span>&bull; {{ machine.reg }}</span>
          }
        </div>
        @if (distance) {
          <div class="distance">
            <ion-icon name="location"></ion-icon>
            {{ distance }} км до объекта
          </div>
        }
        @if (machine.verificationStatus !== "approved") {
          <div class="verified-label">
            <ion-icon name="warning"></ion-icon>
            Техника не подтверждена
          </div>
        }
        @if (machine.verificationStatus === "approved") {
          <div class="verified-label verifed">
            <ion-icon name="checkmark-circle"></ion-icon>
            Техника подтверждена
          </div>
        }
      </div>
      @if (entryDriverContact) {
        <div class="provider-contact">
          <p>Водитель</p>
          <div class="contact">
            <div class="fast-info">
              <ion-icon name="call" class="icon call"></ion-icon>
            </div>
            <div class="contact-wrap">
              <span (click)="callTo(entryDriverContact.contact?.value)">
                {{ entryDriverContact.name }} {{ driverPhone }}
              </span>
            </div>
          </div>
        </div>
      }
    </div>
  }
  @if (!machine && viewAs === "customer") {
    <div class="box-inner machine empty-machine">
      <img src="assets/await.svg" />
      <p>Техника будет указана исполнителем при подписании</p>
    </div>
  }
  @if (!preselectedMachine && viewAs === "supplier") {
    <div class="box-inner choose-machine" [formGroup]="editFormGroup">
      <ion-select
        [formControlName]="'machine'"
        labelPlacement="floating"
        class="custom-picker machine-info"
        [interfaceOptions]="{ header: 'Техника' }"
        label="Выбрать технику"
        (ionChange)="onMachineChange($event)"
        cancelText="Отмена"
      >
        @for (machine of userMachines; track machine) {
          <ion-select-option [value]="machine.uuid">
            {{ machine.model }} {{ machine.reg || "" }} ({{ machine.year }})
          </ion-select-option>
        }
      </ion-select>
      <ion-input
        [formControlName]="'name'"
        (ionInput)="onEnterDriverInfo($event, 'name')"
        labelPlacement="floating"
        label="ФИО"
        minlength="3"
        helperText="Укажите данные водителя"
      ></ion-input>
      <ion-input
        [formControlName]="'phoneNumber'"
        (ionInput)="onEnterDriverInfo($event, 'phone')"
        labelPlacement="floating"
        label="Номер"
        placeholder="+7 (xxx) xxx-xxxx"
      ></ion-input>
    </div>
  }
</div>
