import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { addIcons } from 'ionicons';
import {
  addOutline,
  addCircle,
  addCircleOutline,
  arrowBackOutline,
  briefcaseOutline,
  businessOutline,
  calendarOutline,
  call,
  camera,
  cameraOutline,
  caretDownSharp,
  caretForwardSharp,
  checkmarkCircle,
  chevronBack,
  chevronBackOutline,
  chevronDownCircleOutline,
  chevronForwardOutline,
  close,
  closeCircle,
  ellipse,
  helpBuoyOutline,
  informationCircleOutline,
  location,
  locationSharp,
  pencil,
  peopleOutline,
  personOutline,
  personCircle,
  pin,
  searchOutline,
  square,
  time,
  timeOutline,
  triangle,
  warning,
  checkmarkCircleOutline,
} from 'ionicons/icons';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonApp, IonRouterOutlet],
})
export class AppComponent implements OnInit {
  constructor(private router: Router) {
    addIcons({
      addOutline,
      addCircle,
      addCircleOutline,
      arrowBackOutline,
      businessOutline,
      briefcaseOutline,
      calendarOutline,
      call,
      camera,
      cameraOutline,
      caretForwardSharp,
      caretDownSharp,
      chevronBack,
      chevronBackOutline,
      chevronForwardOutline,
      chevronDownCircleOutline,
      checkmarkCircle,
      checkmarkCircleOutline,
      close,
      closeCircle,
      ellipse,
      helpBuoyOutline,
      informationCircleOutline,
      locationSharp,
      location,
      pencil,
      peopleOutline,
      personOutline,
      personCircle,
      pin,
      searchOutline,
      square,
      time,
      timeOutline,
      triangle,
      warning,
    });
  }

  async ngOnInit() {
    // screen selection

    // if app was start from push

    // if app was started from icon
    if (2 < 1) {
      await this.router.navigate(['home', 'look-for-job']);
    }
  }
}
