import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Api } from '../apollo/api';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertController,
  IonApp,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonModal,
  IonProgressBar,
  IonText,
  IonTitle,
  IonToolbar,
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular/standalone';
import {
  Entry,
  EntryObjects,
  EntryParticipantsContacts,
  EntrySearchItem,
  EnumEntryParticipantsType,
  Machine,
  Maybe,
} from '../../base-types.gql-gen';
import * as translations from '../../interface.json';
import dayjs from 'dayjs';
import { SendRequestComponent } from './send-request/send-request.component';
import { EntrySignAddressComponent } from './entry-sign-address/entry-sign-address.component';
import { EntrySignMachineComponent } from './entry-sign-machine/entry-sign-machine.component';
import { EntrySignSignatureComponent } from './entry-sign-signature/entry-sign-signature.component';
import { EntrySignPriceComponent } from './entry-sign-price/entry-sign-price.component';
import { ProposeEditModalComponent } from '../propose-edit-modal/propose-edit-modal.component';
import { callPhoneNumber, declension, isDefined } from '../utils';
import { DocumentModalComponent } from '../document-modal/document-modal.component';

interface ContactModel {
  name: string;
  phone: string;
}

@Component({
  selector: 'app-entry-sign',
  templateUrl: './entry-sign.component.html',
  styleUrls: ['./entry-sign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonApp,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonProgressBar,
    IonContent,
    IonIcon,
    IonFooter,
    EntrySignAddressComponent,
    EntrySignMachineComponent,
    EntrySignSignatureComponent,
    EntrySignPriceComponent,
    IonButton,
    IonText,
    IonModal,
    DocumentModalComponent,
  ],
})
export class EntrySignComponent implements OnInit {
  // todo: move to service and reuse across all components
  maybeMobappV1Window = window.parent;
  @Input() supplierId?: string;
  @Input() viewAs: EnumEntryParticipantsType = 'customer';
  @Input() proposeId = '';
  @Input() currentUserId = '';
  @Input() entryId = '';
  loading = true;
  requestSend = false;
  entry!: Entry;
  supplier?: Maybe<EntrySearchItem>;
  myPropose: any;
  driverContact: ContactModel = {
    name: '',
    phone: '+7',
  };
  Menus = {
    Main: 'main',
    More: 'more',
  };
  machine?: Machine;
  objectContact?: EntryParticipantsContacts;
  isDoubleSign = false;
  actionMenu = this.Menus.Main;
  isDocumentModalOpen = false;
  documentUrl = '';

  protected agreementURL = 'https://minio.rental-club.ru/public/rc/docs/service_agreement.docx';
  protected contractURL = 'https://minio.rental-club.ru/public/rc/docs/agency_contract.docx';

  constructor(
    private api: Api,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.isDoubleSign = !!this.supplier?.organization?.availableActions?.includes(
      'double_signing' as any,
    );
    this.updateEntryOnInit(this.entryId);
  }

  get pageTitle(): string {
    return this.entry?.status === 'signed' ? 'Заявка подписана' : 'Подписание заявки';
  }

  get entryCategoryName(): string {
    const data = this.entry?.requests?.[0]?.data?.machineTypeData;
    // @ts-ignore
    return translations[data?.name];
  }

  get customerParticipant() {
    return this.entry?.participants?.find((p) => p?.type === 'customer');
  }

  get workTime(): number {
    return this.myPropose?.requests?.[0]?.conditions?.work?.value || 0;
  }

  get unitInfo(): string {
    if (this.myPropose?.requests?.[0]?.conditions?.work?.type === 'trip') {
      return declension(this.workTime, 'рейс', 'рейса', 'рейсов');
    } else if (this.myPropose?.requests?.[0]?.conditions?.work?.type === 'cbm') {
      return declension(this.workTime, 'куб', 'куба', 'кубов');
    }
    return declension(this.workTime, 'смена', 'смены', 'смен');
  }

  get date(): number {
    return this.myPropose?.requests?.[0]?.conditions?.date?.value || 0;
  }

  get startTime(): string {
    if (!this.date) {
      return '';
    }
    return dayjs.unix(this.date).format('HH:mm');
  }

  get workStart(): string {
    if (!this.date) {
      return 'Дата обсуждаема';
    }
    return this.startDate;
  }

  get startDate(): string {
    return dayjs.unix(this.date).locale('ru').format('DD.MM');
  }

  get comment(): string {
    return (
      this.myPropose?.responses?.[0]?.comment || this.entry?.requests?.[0]?.data?.comment || ''
    );
  }

  get customerDate() {
    const customer = this.myPropose?.customerSignature;
    return customer?.ts && dayjs(customer.ts).toDate();
  }

  get supDate() {
    const suppl = this.myPropose?.supplierSignature;
    return suppl?.ts && dayjs(suppl.ts).toDate();
  }

  get signedOtherPerson() {
    const supplier = this.myPropose?.supplierSignature;
    return !supplier?.organization?.users?.find((u: any) => u?.user?.uuid === supplier?.user?.uuid);
  }

  get disableCallButton(): boolean {
    return this.entry.status === 'completed';
  }

  get showDepartureBtn() {
    return (
      this.entry.requests?.[0]?.selected?.[0]?.availableActions?.includes(
        'init_departure_agreement',
      ) && !this.requestSend
    );
  }

  get showCancelBtn() {
    return this.myPropose?.availableActions.includes('cancel_propose');
  }

  get hideCustomerInfo() {
    return this.entry?.status === 'signed' && !this.myPropose?.customerSignature;
  }

  onBackButtonClick() {
    const msg = { from: 'mobappV2', cmd: 'goBack' };
    this.maybeMobappV1Window.postMessage(msg, '*');
  }

  formatDate(date: Date) {
    return dayjs(date).format('DD.MM.YYYY HH:mm');
  }

  updateEntryOnInit(entryId: string) {
    this.api
      .watchEntryById(entryId)
      .refetch()
      .then(({ data }: any) => {
        this.loading = false;
        this.entry = data?.entry;
        this.myPropose = this.entry.proposes?.find((p) => {
          if (this.proposeId) return p?.uuid === this.proposeId;
          else return !!p?.supplierSignature || !!p?.customerSignature;
        });
        if (!this.myPropose) {
          this.mapProposeFromEntry();
        }
        this.cd.markForCheck();
      });
  }

  mapProposeFromEntry() {
    if (this.supplierId) {
      this.supplier = this.entry.searchResult?.items?.find(
        (i) => i?.organization?.uuid === this.supplierId,
      );
    }
    this.myPropose = {
      requests: this.entry.requests,
      objects: this.entry.objects,
      supplier: {
        organization: this.supplier?.organization,
        company: this.supplier?.company,
        user: this?.supplier?.organization?.users?.[0]?.user,
      },
    };
    const ereq = this.entry.requests[0];
    const preq = this.myPropose.requests[0];
    if (ereq && preq) {
      preq.payment ??= ereq.payment;
      if (preq.payment && !isDefined(preq.payment.value) && ereq?.conditions?.budget?.value) {
        preq.payment.value = ereq.conditions.budget.value;
      }
    }
    this.cd.detectChanges();
  }

  callToUs() {
    let phoneNumber;
    if (this.viewAs === 'customer') {
      phoneNumber = this.myPropose?.supplierSignature?.user?.phone;
    } else {
      phoneNumber = this.entry.customer?.user?.phone;
    }
    let phoneString = `+${phoneNumber ? phoneNumber : ''}`;
    if (phoneNumber && phoneNumber?.length === 7) {
      phoneString = phoneNumber;
    }
    callPhoneNumber(phoneString);
  }

  selectMachine(machine?: Machine) {
    this.machine = machine;
  }

  enterDriverInfo(driver: ContactModel) {
    this.driverContact = driver;
  }

  enterObjectContact(contact: any) {
    this.objectContact = contact;
  }

  async editPropose() {
    const modal = await this.modalCtrl.create({
      component: ProposeEditModalComponent,
      componentProps: { entry: this.entry, myPropose: this.myPropose },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.myPropose = data;
      this.cd.detectChanges();
    }
  }

  async sendRequest() {
    const modal = await this.modalCtrl.create({
      component: SendRequestComponent,
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.cd.markForCheck();
      this.api
        .sendRequestOut(this.entry.requests?.[0]?.selected?.[0]?.id || '', data.date, data.comment)
        .then(() => {
          this.cd.markForCheck();
          this.requestSend = true;
        })
        .then(async () => {
          const toast = await this.toastCtrl.create({
            message:
              'Заявка на выезд отправлена на согласование. Отслеживайте статус заявки через WhatsApp',
            duration: 2000,
            position: 'top',
          });
          await toast.present();
        });
    }
  }

  async rejectOffer() {
    const alert = await this.alertCtrl.create({
      header: 'Вы уверены, что хотите отказаться от выбранного поставщика?',
      buttons: [
        'Нет',
        {
          text: 'Да',
          handler: async () => {
            const loading = await this.loadingCtrl.create();
            await loading.present();
            this.api
              .cancelPropose(this.myPropose?.ref || '')
              .then(() => {
                loading.dismiss();
                this.onBackButtonClick();
              })
              .catch(async () => {
                await loading.dismiss();
                const toast = await this.toastCtrl.create({
                  message: 'Произошла ошибка, попробуйте позже',
                  duration: 3000,
                  position: 'top',
                });
                await toast.present();
              });
          },
        },
      ],
    });
    await alert.present();
  }

  async signAndSend() {
    const canProceed = await this.errorHandling();
    if (!canProceed) return;
    const loading = await this.loadingCtrl.create();
    await loading.present();
    let signFunc;
    if (this.viewAs === 'customer') {
      const contactForObject = {
        uuid: this.objectContact?.uuid,
        additional: this.objectContact?.additional,
        isDriver: false,
        name: this.objectContact?.name,
        phone: this.objectContact?.contact?.value,
        unions: [{ uuid: this.myPropose.objects[0].uuid, resource: 'object' }],
      };
      const objects = this.myPropose.objects.map((o: EntryObjects) => {
        return {
          uuid: o.uuid,
          ref: o.ref,
          address: {
            type: o.address?.type || '',
            coordinates: o.address?.coordinates,
            fullAddress: o.address?.fullAddress,
          },
        };
      });
      signFunc = this.api
        .entrySendProposal(
          this.entry,
          this.myPropose,
          [contactForObject],
          objects,
          'customer',
          true,
        )
        .then(async () => {
          await loading.dismiss();
          await this.router.navigate(['/entry-details-machine'], {
            queryParams: { entryId: this.entry.uuid },
          });
        });
    } else {
      signFunc = this.api
        .changeEntry(
          this.updateEntryFields(),
          this.myPropose?.supplier?.organization?.uuid || '',
          'sign',
        )
        .then(async () => {
          this.cd.markForCheck();
          await loading.dismiss();
          const toast = await this.toastCtrl.create({
            message: 'Вы подписали заявку',
            duration: 2000,
            position: 'top',
          });
          await toast.present();
          const msg = { from: 'mobappV2', cmd: 'signed-entry', entry: this.entry };
          this.maybeMobappV1Window.postMessage(msg, '*');
        });
    }
    await signFunc.catch(async () => {
      await loading.dismiss();
      const toast = await this.toastCtrl.create({
        message: 'Ошибка подписания заявки. Попробуйте позже',
        duration: 2000,
        position: 'top',
      });
      await toast.present();
    });
  }

  private async errorHandling() {
    if (this.viewAs === 'supplier' && !this.machine && !this.myPropose?.responses?.[0]?.machine) {
      const toast = await this.toastCtrl.create({
        message: 'Укажите технику',
        duration: 2000,
        position: 'top',
      });
      await toast.present();
      return false;
    }

    const price =
      this.myPropose?.requests?.[0]?.payment?.value ||
      this.myPropose?.responses?.[0]?.data?.budgetValue;
    const noContact =
      !this.objectContact && this.viewAs === 'customer' && this.entry.status !== 'signed';
    if (
      this.myPropose?.requests?.[0]?.payment.type === 'none' ||
      !price ||
      !this.date ||
      !this.startTime ||
      noContact
    ) {
      let text = `${this.myPropose?.requests?.[0]?.payment.type === 'none' ? '<strong>- тип оплаты</strong>' : ''}`;
      text += `${!price ? '<strong>- цена</strong><br>' : ''}`;
      text += `${!this.date || !this.startTime ? '<strong>- дата и время</strong><br>' : ''}`;
      text += `${noContact ? '<strong>- контакт на объекте' : ''}`;
      const alert = await this.alertCtrl.create({
        header: 'Ошибка',
        subHeader: 'Для подписания заявки необходимо указать следующее:',
        message: text,
        buttons: [
          {
            text: 'Ok',
          },
        ],
      });
      await alert.present();
      return false;
    }
    return true;
  }

  private updateEntryFields() {
    const customer = this.myPropose?.customerSignature;
    const sup = this.myPropose?.supplier;
    const driver = this.driverContact?.name
      ? this.driverContact
      : {
          name: '',
          phone: '',
        };
    const machine = this.machine || this.myPropose?.responses?.[0]?.machine;
    return {
      uuid: this.entry.uuid,
      uuidPropose: this.myPropose?.uuid,
      uuidResource: machine?.uuid,
      uuidCustomerCompany: customer?.company?.uuid,
      uuidProposeCompany: sup?.company?.uuid,
      uuidProposeUser: sup?.user?.uuid || this.currentUserId,
      driver,
    };
  }

  onAgreementBtnClick(ev: Event) {
    ev.preventDefault();
    if (navigator.userAgent.includes('iPhone;')) {
      open(this.agreementURL);
    } else {
      this.documentUrl = this.agreementURL;
      this.isDocumentModalOpen = true;
    }
  }

  onContractBtnClick(ev: Event) {
    ev.preventDefault();
    if (navigator.userAgent.includes('iPhone;')) {
      open(this.contractURL);
    } else {
      this.documentUrl = this.contractURL;
      this.isDocumentModalOpen = true;
    }
  }

  onDocumentModalDismiss(ev: Event) {
    ev.preventDefault();
    this.documentUrl = '';
    this.isDocumentModalOpen = false;
  }

  onDocumentModalClose() {
    setTimeout(() => {
      //run in new macrotask, because stupid bug with changeDetection
      this.documentUrl = '';
      this.isDocumentModalOpen = false;
      this.cd.detectChanges();
    });
  }
}
