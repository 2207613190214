<ion-header [class.invisible]="!selectedMachineType">
  <ion-toolbar>
    <ion-title>Аренда техники</ion-title>
    <ion-buttons slot="start">
      <ion-back-button [defaultHref]="'/home/entries'"></ion-back-button>
    </ion-buttons>
    <ion-text slot="end" color="primary" class="ion-padding-end">Диспетчер</ion-text>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding no-border" [class.invisible]="!selectedMachineType">
  <!--    Тип техники   -->

  @if (selectedMachineType) {
    <ion-input
      [readonly]="true"
      class="ion-margin-vertical"
      fill="outline"
      [value]="selectedMachineType.nameRU"
      (click)="onMachineTypeInputClick($event)"
    >
      <ion-icon slot="end" name="chevron-forward-outline" aria-hidden="true"></ion-icon>
    </ion-input>
  }
  <ion-modal [isOpen]="isMachineTypeModalOpen" (didDismiss)="onMachineTypeModalDismiss($event)">
    <ng-template>
      <app-machine-type-selection-modal
        (rcSelect)="onMachineTypeModalRcSelect($event)"
        class="ion-page"
      ></app-machine-type-selection-modal>
    </ng-template>
  </ion-modal>

  <!--    Характеристики   -->

  @if (selectedMachineType && selectedMachineType.params) {
    <ion-input
      (click)="onMachineParamsInputClick($event)"
      [readonly]="true"
      [value]="selectedMachineParamsText"
      class="ion-margin-vertical"
      fill="outline"
      placeholder="Характеристики (необязательно)"
    >
      <ion-icon slot="end" name="chevron-forward-outline" aria-hidden="true"></ion-icon>
    </ion-input>

    <ion-modal
      [isOpen]="isMachineParamsModalOpen"
      (ionModalDidDismiss)="onMachineParamsModalDismiss($event)"
    >
      <ng-template>
        <app-machine-params-selection-modal
          [machineType]="selectedMachineType"
          [machineParamValues]="selectedMachineParams"
          (appClose)="onMachineParamsModalRcClose()"
          (appSelect)="onMachineParamsRcSelect($event)"
          class="ion-page"
        ></app-machine-params-selection-modal>
      </ng-template>
    </ion-modal>
  }
  <!--    Заказчик   -->
  <div class="rc-suggest-list-wrapper">
    <ion-input
      (ionInput)="onOrganizationInputChange($event)"
      [debounce]="666"
      [value]="selectedOrganizationInputVal"
      class="ion-margin-vertical"
      fill="outline"
      placeholder="Заказчик"
    ></ion-input>
    @if (organizations.length > 0) {
      <div class="rc-suggest-list">
        <ion-list class="ion-padding">
          @for (item of organizations; track item.id) {
            <ion-item (click)="onOrganizationSuggestClick($event, $index)">
              <div>{{ item.name }}</div>
            </ion-item>
          }
        </ion-list>
      </div>
    }
  </div>

  <div class="rc-suggest-list-wrapper">
    <ion-input
      (ionFocus)="onContactInputFocus($event)"
      (ionBlur)="onContactInputBlur($event)"
      (ionInput)="onContactInputInput($event)"
      [value]="selectedContactInputVal"
      [disabled]="!selectedOrganization"
      class="ion-margin-vertical"
      fill="outline"
      placeholder="Офисный контакт"
    >
      @if (!selectedOrganization) {
        <ion-icon
          slot="end"
          name="information-circle-outline"
          aria-hidden="true"
          id="info-contact-trigger"
        ></ion-icon>
        <ion-popover trigger="info-contact-trigger" triggerAction="hover">
          <ng-template>
            <ion-content class="ion-padding">
              Для ввода контакта сначала укажите заказчика
            </ion-content>
          </ng-template>
        </ion-popover>
      }
    </ion-input>

    @if (isContactListOpen) {
      <div class="rc-suggest-list">
        <ion-list class="ion-padding">
          <ion-item-divider>
            <ion-label>Действия</ion-label>
          </ion-item-divider>
          <ion-item (click)="onNewContactItemClick($event)" class="ion-no-padding">
            <div>
              <ion-icon name="add-circle-outline" class="ion-padding-end"></ion-icon>
              Добавить новый контакт
            </div>
          </ion-item>
          <ion-item-divider>
            <ion-label>Контакты заказчика</ion-label>
          </ion-item-divider>
          @for (contact of organizationContactsSuggests; track $index) {
            <ion-item (click)="onContactSuggestClick($event, $index)">
              <div>{{ contact.name }} {{ contact.phoneNumber }}</div>
            </ion-item>
          }
        </ion-list>
      </div>
    }
    <ion-modal
      class="add-new-contact-modal"
      [isOpen]="isNewContactModalOpen"
      (didDismiss)="onNewContactModalDismiss()"
    >
      <ng-template>
        <div class="new-contact-card no-border">
          <h3 class="ion-padding-horizontal">Добавление офисного контакта</h3>
          <div class="ion-padding" [formGroup]="newContactFormGroup">
            <ion-input
              class="ion-margin-bottom"
              placeholder="Имя *"
              fill="outline"
              formControlName="firstName"
            ></ion-input>
            <ion-input
              class="ion-margin-bottom"
              placeholder="Фамилия"
              fill="outline"
              formControlName="lastName"
            ></ion-input>
            <ion-input
              class="ion-margin-bottom"
              placeholder="Номер телефона *"
              [maskito]="maskitoPhoneNumberMask"
              [maskitoElement]="maskPredicate"
              fill="outline"
              formControlName="phoneNumber"
            ></ion-input>
            <div class="buttons">
              <ion-button fill="clear" (click)="onNewContactModalCancelClick($event)">
                Отмена
              </ion-button>
              <ion-button fill="clear" (click)="onNewContactModalOkClick($event)">
                Сохранить
              </ion-button>
            </div>
          </div>
        </div>
      </ng-template>
    </ion-modal>
  </div>
  <!--    Адрес   -->
  <div class="rc-address-list">
    @for (newAddressInput of addressObjects; track $index) {
      <div class="rc-suggest-list-wrapper">
        <div class="add-icon-after-address-input">
          <ion-input
            fill="outline"
            [placeholder]="newAddressInput.placeholder"
            [value]="newAddressInput.fullAddress"
            [disabled]="!selectedOrganization"
            (ionFocus)="onAddressObjectInputFocus($event, $index)"
            (ionBlur)="onAddressObjectInputBlur($event)"
            (ionInput)="onAddressObjectInputInput($event)"
          >
            @if ($index > 0) {
              <ion-icon
                slot="end"
                name="close"
                aria-hidden="true"
                (click)="onRemoveAddressFieldClick($event, newAddressInput)"
              ></ion-icon>
            }
            @if (!selectedOrganization) {
              <ion-icon
                slot="end"
                name="information-circle-outline"
                aria-hidden="true"
                id="info-trigger"
              ></ion-icon>
              <ion-popover trigger="info-trigger" triggerAction="hover">
                <ng-template>
                  <ion-content class="ion-padding">
                    Для ввода адреса сначала укажите заказчика
                  </ion-content>
                </ng-template>
              </ion-popover>
            }
          </ion-input>
          @if (newAddressInput.fullAddress) {
            <ion-icon
              color="primary"
              size="medium"
              class="large-pin-icon ion-padding-start"
              name="location"
              (click)="onPreviewAddressIconClick($event, newAddressInput.coords)"
            ></ion-icon>
          }
        </div>
        @if (focusedAddressObjectInputIdx === $index) {
          <div class="rc-suggest-list">
            <ion-list class="ion-padding">
              <ion-item-divider class="ion-no-padding">
                <ion-label>Действия</ion-label>
              </ion-item-divider>
              <ion-item
                (click)="onNewAddressObjectItemClick($event, newAddressInput)"
                class="ion-no-padding"
              >
                <ion-icon name="add-circle-outline" class="ion-padding-end"></ion-icon>
                Добавить новый адрес
              </ion-item>

              @for (group of addressObjectsSuggests; track $index) {
                @if (group.address.length) {
                  <ion-item-divider class="ion-no-padding">
                    <ion-label>{{ group.title }}</ion-label>
                  </ion-item-divider>
                  @for (addr of group.address; track $index) {
                    <ion-item
                      [button]="true"
                      [detail]="false"
                      (click)="onAddressObjectSuggestClick($event, addr, newAddressInput)"
                      class="ion-no-padding"
                    >
                      <div>
                        @if (addr.title) {
                          <strong>{{ addr.title }},&nbsp;</strong>
                        }
                        <span>{{ addr.fullAddress }}</span>
                      </div>
                    </ion-item>
                  }
                }
              }
              @if (isAddressObjectGroupsEmpty) {
                <ion-item lines="none">
                  <ion-text>Совпадений не найдено</ion-text>
                </ion-item>
              }
            </ion-list>
          </div>
        }
      </div>
    }
  </div>
  <ion-modal
    [isOpen]="previewAddressCoords.length > 0"
    (ionModalDidDismiss)="onPreviewAddressModalDismiss($event)"
  >
    <ng-template>
      <app-preview-address-with-map-modal
        [coords]="previewAddressCoords"
        (appClose)="onPreviewAddressModalCancel()"
      ></app-preview-address-with-map-modal>
    </ng-template>
  </ion-modal>
  <ion-modal
    [isOpen]="isAddressModalOpen"
    (ionModalDidDismiss)="onSearchAddressModalDismiss($event)"
  >
    <ng-template>
      <app-search-address-with-map-modal
        (appClose)="onSearchAddressModalClose()"
        (addressSelect)="onSearchAddressModalSelect($event)"
      ></app-search-address-with-map-modal>
    </ng-template>
  </ion-modal>
  @if (addressObjects.length < 5) {
    <ion-button
      (click)="onAddAddressObjectBtnClick($event)"
      [disabled]="!isFormAddressValid"
      class="ion-margin-top"
      fill="outline"
      size="small"
    >
      Еще адрес +
    </ion-button>
  } @else {
    <ion-button class="ion-margin-top" fill="outline" size="small">
      Больше не можем добавить 😔
    </ion-button>
  }

  <!--    Дата и время   -->

  <ion-input
    [readonly]="true"
    class="ion-margin-vertical"
    fill="outline"
    placeholder="Дата"
    (click)="onDateTimeInputClick($event)"
    [value]="selectedDateFormatted"
  >
    <ion-icon slot="end" name="calendar-outline" aria-hidden="true"></ion-icon>
  </ion-input>
  <ion-modal
    [isOpen]="isDateTimeModalOpen"
    (ionModalDidDismiss)="onDateTimeModalDismiss($event)"
    class="datetime-wheel-modal"
  >
    <ng-template>
      <app-datetime-wheels-modal
        (rcDateTimeChange)="onRcDateTimeChange($event)"
      ></app-datetime-wheels-modal>
    </ng-template>
  </ion-modal>

  <!--    Количество смен   -->

  @if (selectedMachineUnit) {
    <div class="unit-options ion-margin-vertical">
      @if (workUnits.length > 1) {
        <ion-select
          justify="space-between"
          fill="outline"
          [value]="selectedMachineUnit.name"
          (ionChange)="onWorkUnitSelectChange($event)"
          [interfaceOptions]="{ header: 'Объем работ' }"
          cancelText="Отмена"
          okText="Ок"
        >
          @for (unit of workUnits; track $index) {
            <ion-select-option [value]="unit.name">{{ unit.ruName }}</ion-select-option>
          }
        </ion-select>
      } @else {
        <ion-input fill="outline" [value]="workUnits[0].ruName" [readonly]="true"></ion-input>
      }
      <ion-input
        (ionChange)="onWorkValueInputChange($event)"
        [value]="selectedWorkValue"
        class="counter"
        fill="outline"
        maxlength="5"
        inputmode="numeric"
      ></ion-input>
    </div>
  }

  <!--    Способ оплаты   -->
  <ion-select
    label="Способ оплаты"
    fill="outline"
    [value]="selectedPaymentType.value"
    (ionChange)="onPaymentSelectChange($event)"
    cancelText="Отмена"
    okText="Ок"
  >
    @for (paymentType of paymentTypes; track paymentType.value) {
      <ion-select-option [value]="paymentType.value">{{ paymentType.label }}</ion-select-option>
    }
  </ion-select>
  <ion-input
    (ionChange)="onPriceInputChange($event)"
    [disabled]="selectedPaymentType.value === 'none'"
    class="ion-margin-vertical"
    fill="outline"
    placeholder="Бюджет (необязательно)"
    inputmode="numeric"
    [maskito]="priceMask"
    [maskitoElement]="maskPredicate"
  >
    <ion-text slot="end">₽</ion-text>
  </ion-input>
  @if (selectedPaymentType.value === "none") {
    <ion-note>Для указания бюджета выберете способ оплаты</ion-note>
  }

  <!--    Комментарий   -->

  <ion-textarea
    (ionChange)="onCommentInputChange($event)"
    [autoGrow]="true"
    fill="outline"
    placeholder="Комментарий поставщику"
    class="ion-margin-vertical"
  ></ion-textarea>
  <ion-textarea
    (ionChange)="onInternalCommentInputChange($event)"
    [autoGrow]="true"
    fill="outline"
    placeholder="Внутренний комментарий"
    class="ion-margin-vertical"
  ></ion-textarea>
  <ion-text>
    @if (this.providersCountTxt) {
      <span class="green-bold-text">{{ this.providersCountTxt }}</span>
      <span>&nbsp;в регионе</span>
    }
  </ion-text>
</ion-content>
<ion-footer appHideOnKeyboard class="ion-no-border" [class.invisible]="!selectedMachineType">
  <ion-button
    [disabled]="!isFormValid"
    (click)="onCreateEntryBtnClick($event)"
    class="ion-padding"
    expand="block"
    size="large"
    color="rcyellow"
  >
    Подобрать технику
  </ion-button>
</ion-footer>
