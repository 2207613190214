<ion-header>
  <ion-toolbar>
    <ion-title>База</ion-title>
    <ion-buttons slot="start">
      <ion-back-button [defaultHref]="'/home/profile?segment=info'"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <app-address-pick
    [addressOrig]="currentAddress"
    (entryAddressChanges)="onAddressChange($event)"
  ></app-address-pick>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-button
    (click)="onSaveMachineBaseClick($event)"
    class="ion-padding"
    expand="block"
    size="large"
    color="rcyellow"
  >
    Сохранить
  </ion-button>
</ion-footer>
