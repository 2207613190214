import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProfileMachineBaseSetAuthCurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProfileMachineBaseSetAuthCurrentUserQuery = { __typename?: 'Query', authCurrentUser?: { __typename?: 'User', organizations: Array<{ __typename?: 'Organization', uuid: string, bases: Array<{ __typename?: 'Base', address: { __typename?: 'BaseAddress', coordinates: Array<number>, fullAddress: string } }> } | null> } | null };

export const ProfileMachineBaseSetAuthCurrentUserDocument = gql`
    query ProfileMachineBaseSetAuthCurrentUser {
  authCurrentUser {
    organizations {
      uuid
      bases {
        address {
          coordinates
          fullAddress
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileMachineBaseSetAuthCurrentUserQueryGql extends Apollo.Query<ProfileMachineBaseSetAuthCurrentUserQuery, ProfileMachineBaseSetAuthCurrentUserQueryVariables> {
    override document = ProfileMachineBaseSetAuthCurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }