@for (object of objects; track object; let idx = $index) {
  <div class="info-content">
    @if (object) {
      <div class="address-info">
        <div class="fast-info">
          <ion-icon name="location"></ion-icon>
        </div>
        <div (click)="openFullMap(object)">
          @if (object?.address?.fullAddress) {
            <div>{{ getAddress(object) }}</div>
          }
        </div>
      </div>
      @if (viewAs === "supplier" || entry.status === "signed") {
        <div class="contact">
          <div class="fast-info">
            <ion-icon name="call" slot="start" class="icon when-icon"></ion-icon>
          </div>
          <div class="contact-wrap">
            <span (click)="callToContact(contactForObject(object))">
              {{ getContactItem(contactForObject(object)) }}
            </span>
          </div>
        </div>
      }
      @if (viewAs === "customer" && entry.status !== "signed") {
        <div class="contact">
          <div class="fast-info">
            <ion-icon name="call"></ion-icon>
          </div>
          <div class="contact-wrap">
            <ion-select
              class="custom-picker"
              [interfaceOptions]="{ header: 'Контактное лицо на объекте' }"
              [(ngModel)]="selectedContact"
              (ngModelChange)="onContactChange($event)"
              placeholder="Контактное лицо на объекте"
              cancelText="Отмена"
            >
              @for (contact of entryObjectContact; track contact) {
                <ion-select-option [value]="contact">
                  {{ contact?.name }}
                  <br />
                  {{ getPhone(contact) }}
                </ion-select-option>
              }
            </ion-select>
          </div>
        </div>
        <span id="open-modal" expand="block">Добавить контакт</span>
        <ion-modal #modal trigger="open-modal">
          <ng-template>
            <div class="title">
              <h2>Укажите контакт на объекте</h2>
              <ion-icon name="close" (click)="close()"></ion-icon>
            </div>
            <div [formGroup]="editFormGroup" class="wrapper">
              <ion-input
                [formControlName]="'name'"
                labelPlacement="floating"
                label="ФИО"
                minlength="3"
              ></ion-input>
              <ion-input
                [formControlName]="'phoneNumber'"
                labelPlacement="floating"
                label="Номер"
                placeholder="+7 (xxx) xxx-xxxx"
              ></ion-input>
            </div>
            <ion-button (click)="addContact()" class="add">Сохранить</ion-button>
          </ng-template>
        </ion-modal>
      }
    }
  </div>
}
