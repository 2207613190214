import { ChangeDetectionStrategy, Component } from '@angular/core';
import dayjs from 'dayjs';
import {
  IonButtons,
  IonContent,
  IonDatetime,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-machine-details',
  templateUrl: 'send-request.component.html',
  styleUrls: ['./send-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonIcon,
    IonTitle,
    IonContent,
    IonItem,
    IonTextarea,
    IonDatetime,
    IonFooter,
  ],
})
export class SendRequestComponent {
  pickedDate?: Date;
  startDate = '';
  comment = '';
  datepickerStartDateTime: string = this._pickerStartDateTime();
  datepickerEndDateTime: string = dayjs()
    .add(2, 'days')
    .add(2, 'month')
    .format('YYYY-MM-DDTHH:mmZ');

  constructor() {}

  updateStartDateTime() {
    this.startDate = dayjs(this.pickedDate).toString();
  }

  close() {}

  private _pickerStartDateTime() {
    const date = dayjs().subtract(1, 'month');
    if (date.minute() > 30) {
      date.add(1, 'hour').minute(0);
    } else {
      date.minute(30);
    }
    return date.format('YYYY-MM-DDTHH:mm');
  }
}
