<ion-header>
  <ion-toolbar>
    <ion-title>Адрес объекта</ion-title>
    <ion-buttons slot="start">
      <ion-button
        class="ion-margin-vertical"
        shape="round"
        size="medium"
        slot="end"
        (click)="onCloseBtnClick($event)"
      >
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div #yaMapContainer class="yamap"></div>
