@if (proposePaymentType.length || price) {
  <div class="price-and-payment">
    @if (price) {
      <div class="price-block">
        <span class="price">{{ price | maskito: priceMask }}</span>
        &nbsp;₽/{{ unitWithoutCount }}
      </div>
    }
    @if (proposePaymentType.length) {
      <div>{{ proposePaymentType }}</div>
    }
    @if (equipments?.length) {
      @for (equipment of equipments; track equipment.name) {
        <div class="price-block">
          <span class="price">{{ equipment.price || 0 | maskito: priceMask }}</span>
          &nbsp;₽/{{ unitWithoutCount }}
        </div>
        <div>{{ equipment.name }}</div>
      }
    }
    @if (services?.length) {
      @for (service of services; track service.name) {
        <div class="price-block">
          <span class="price">{{ service.price || 0 | maskito: priceMask }}</span>
          &nbsp;₽
        </div>
        <div>{{ service.name }}</div>
      }
    }
  </div>
}
