import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-document-modal',
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonContent,
    IonTitle,
    IonButton,
    IonIcon,
    IonSpinner,
  ],
  templateUrl: './document-modal.component.html',
  styleUrl: './document-modal.component.scss',
})
export class DocumentModalComponent implements AfterViewInit {
  @Output() appClose = new EventEmitter<void>();
  @Input() documentUrl: string | undefined;

  @ViewChild('object') object?: ElementRef<HTMLObjectElement>;

  constructor() {}

  ngAfterViewInit(): void {
    this.loadIframe();
  }

  private loadIframe() {
    const objectEl = this.object?.nativeElement;
    if (objectEl) {
      const defaultSrc = 'about:blank';
      objectEl.data = defaultSrc;
      setTimeout(() => {
        objectEl.data = `https://docs.google.com/viewer?embedded=true&url=${this.documentUrl}`;
      }, 0);
      setTimeout(() => {
        if (objectEl.contentDocument?.location.href === defaultSrc) {
          console.log('retry');
          this.loadIframe();
        }
      }, 1000);
    }
  }

  onCloseBtnClick(ev: Event) {
    ev.preventDefault();
    this.appClose.emit();
  }
}
