<ion-app>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button [defaultHref]="'/'" (click)="onBackButtonClick()"></ion-back-button>
      </ion-buttons>
      <ion-title>{{ pageTitle }}</ion-title>
      @if (loading) {
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
      }
    </ion-toolbar>
  </ion-header>
  <ion-content scroll-events="true">
    @if (!loading) {
      <div class="container">
        <div class="box-container">
          <div class="box-title">Детали заказа</div>
          <div class="box-inner entry-info">
            <div class="header">
              <div class="header-content">
                <div class="category">
                  {{ entryCategoryName }}
                </div>
                @if (entry.status !== "signed" && viewAs === "customer") {
                  <img src="./assets/pen.svg" (click)="editPropose()" />
                }
              </div>
            </div>
            <div class="when-where">
              <div class="when">
                <ion-icon name="time" class="icon when-icon"></ion-icon>
                <span class="content-head">{{ workStart }},&nbsp;</span>
                @if (startTime) {
                  <span>{{ startTime }},&nbsp;</span>
                }
                <span>{{ workTime }} {{ unitInfo }}</span>
              </div>
              <app-entry-sign-address
                (selectContact)="enterObjectContact($event)"
                [myPropose]="myPropose"
                [entry]="entry"
                [viewAs]="viewAs"
              ></app-entry-sign-address>
            </div>
            <app-entry-sign-price
              [myPropose]="myPropose"
              [entry]="entry"
              [viewAs]="viewAs"
            ></app-entry-sign-price>
            @if (comment.trim()) {
              <div class="entry-comment">
                <div class="text-box">{{ comment }}</div>
              </div>
            }
          </div>
        </div>
        <div class="box-container">
          <div class="box-title">Техника</div>
          <app-entry-sign-machine
            [myPropose]="myPropose"
            [entry]="entry"
            [viewAs]="viewAs"
            (selectMachine)="selectMachine($event)"
            (enterDriverInfo)="enterDriverInfo($event)"
          ></app-entry-sign-machine>
        </div>
        @if (!hideCustomerInfo) {
          <div class="box-container">
            <div class="box-title">Заказчик</div>
            <div class="box-inner">
              <app-entry-sign-signature
                [myPropose]="myPropose"
                [entry]="entry"
                [role]="'customer'"
              ></app-entry-sign-signature>
              @if (customerDate) {
                <div class="offer-sign offer-sign--provider">
                  <img
                    class="offer-sign-logo"
                    src="assets/icon/rental-sign-logo.svg"
                    alt="Rental Club"
                  />
                  <div class="offer-sign-text">
                    <strong>
                      Подписано
                      <span class="sign-date">{{ formatDate(customerDate) }}</span>
                    </strong>
                    <br />
                    Простая электронная подпись
                  </div>
                </div>
              }
            </div>
          </div>
        }
        <div class="box-container">
          <div class="box-title">Поставщик</div>
          <div class="box-inner">
            <app-entry-sign-signature
              [myPropose]="myPropose"
              [entry]="entry"
              [role]="'supplier'"
              [supplier]="supplier"
              [currentUserId]="currentUserId"
            ></app-entry-sign-signature>
            @if (signedOtherPerson && entry.status === "signed") {
              <div>Заявка с исполнителем согласована устно</div>
            }
            @if (entry.status === "signed") {
              <div class="offer-sign offer-sign--provider">
                <img
                  class="offer-sign-logo"
                  src="assets/icon/rental-sign-logo.svg"
                  alt="Rental Club"
                />
                <div class="offer-sign-text">
                  <strong>
                    Подписано
                    <span class="sign-date">{{ formatDate(supDate) }}</span>
                  </strong>
                  <br />
                  Простая электронная подпись
                </div>
              </div>
            }
          </div>
        </div>
        @if (entry.status !== "signed") {
          <div class="document-container">
            <ion-text>Нажимая на кнопку “Подписать” вы соглашаетесь &nbsp;</ion-text>
            <div class="conditions-alignment">
              <ion-text class="conditions">с условиями</ion-text>
              <ion-button
                (click)="onAgreementBtnClick($event)"
                size="small"
                fill="clear"
                class="ion-no-margin"
              >
                договора оказания услуг
              </ion-button>
            </div>
            @if (viewAs === "supplier") {
              <div class="conditions-alignment">
                <ion-text class="conditions">и</ion-text>
                <ion-button
                  (click)="onContractBtnClick($event)"
                  size="small"
                  fill="clear"
                  class="ion-no-margin"
                >
                  агентского договора
                </ion-button>
              </div>
            }
            <ion-modal
              [isOpen]="isDocumentModalOpen"
              (ionModalDidDismiss)="onDocumentModalDismiss($event)"
            >
              <ng-template>
                <app-document-modal
                  (appClose)="onDocumentModalClose()"
                  [documentUrl]="documentUrl"
                ></app-document-modal>
              </ng-template>
            </ion-modal>
          </div>
        }
      </div>
    }
  </ion-content>
  @if (!loading) {
    <ion-footer>
      <ion-toolbar no-padding>
        @if (entry.status !== "signed") {
          @if (actionMenu === Menus.Main) {
            <div class="footer-buttons">
              <button ion-button class="button-more" tappable (click)="actionMenu = Menus.More">
                <span class="footer-button-txt">
                  <img src="assets/icon/ellipsis.svg" alt="еще..." />
                </span>
              </button>

              <button ion-button class="button-action button-sign" tappable (click)="signAndSend()">
                <span class="footer-button-txt">Подписать и отправить</span>
              </button>
            </div>
          }
          @if (actionMenu === Menus.More) {
            <div class="footer-buttons vertical">
              <button
                ion-button
                class="button-call"
                [disabled]="disableCallButton"
                tappable
                (click)="callToUs()"
              >
                <span class="footer-button-txt">Позвонить</span>
              </button>
              <button
                ion-button
                mode="ios"
                class="button-more no-border"
                tappable
                (click)="actionMenu = Menus.Main"
              >
                <span class="footer-button-txt">
                  <ion-icon name="close"></ion-icon>
                </span>
              </button>
            </div>
          }
        }
        @if (entry.status === "signed") {
          @if (actionMenu === Menus.Main) {
            <div class="footer-buttons">
              @if (viewAs === "customer") {
                <button ion-button class="button-more" tappable (click)="actionMenu = Menus.More">
                  <span class="footer-button-txt">
                    <img src="assets/icon/ellipsis.svg" alt="еще..." />
                  </span>
                </button>
              }
              <button
                ion-button
                class="button-action"
                [disabled]="disableCallButton"
                tappable
                (click)="callToUs()"
              >
                <span class="footer-button-txt">Позвонить</span>
              </button>
            </div>
          }
          @if (actionMenu === Menus.More) {
            <div class="footer-buttons vertical">
              <button
                [disabled]="!showDepartureBtn"
                class="button-secondary"
                tappable
                (click)="sendRequest()"
              >
                <span class="footer-button-txt">Отправить заявку на выезд</span>
              </button>
              <button
                [disabled]="!showCancelBtn"
                class="button-secondary"
                tappable
                (click)="rejectOffer()"
              >
                <span class="footer-button-txt">Отменить выбранного поставщика</span>
              </button>
              <button class="button-more no-border" tappable (click)="actionMenu = Menus.Main">
                <span class="footer-button-txt">
                  <ion-icon name="close"></ion-icon>
                </span>
              </button>
            </div>
          }
        }
      </ion-toolbar>
    </ion-footer>
  }
</ion-app>
