import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { environment } from './environments/environment';

const firebaseConfig = {
  apiKey: 'AIzaSyC-05Y5YDC8dr1OffDGnpfIzSykoZCVQx8',
  authDomain: 'rentalclub-45573.firebaseapp.com',
  databaseURL: 'https://rentalclub-45573.firebaseio.com',
  projectId: 'rentalclub-45573',
  storageBucket: 'rentalclub-45573.appspot.com',
  messagingSenderId: '1077211678670',
  appId: '1:1077211678670:web:9181ace804d50603a2cd9f',
  measurementId: 'G-MWEKLN0S4L',
};

if (environment.firebaseAppcheckDebugToken) {
  (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6LdJ97UpAAAAAGND4DU63spV8s5k-yDIbRmns67j'),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});
