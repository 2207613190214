import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRange,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { CreateEntryInput } from '../../base-types.gql-gen';
import { CheckboxCustomEvent, RangeCustomEvent, ToggleCustomEvent } from '@ionic/angular';
import { MachineTypeX } from '../machine-type-selection-modal/machine-type-selection-modal.component';
import { isDefined } from '../utils';

type MachineParamUIData = {
  name: string;
  nameRu: string;
  type: string;
  unit: string;
  // spec
  rangeMin?: number;
  rangeMax?: number;
  selectOption?: string[];
  // values
  rangeValue?: number[];
  selectNames?: string[];
  boolValue?: boolean;
};

export type MachineParamValueDto = NonNullable<CreateEntryInput['machineParams']>[number];

export type MachineParamsAppSelectEvent = {
  data: MachineParamValueDto[];
  txt: string;
  txtShort: string;
};

@Component({
  selector: 'app-machine-params-selection-modal',
  standalone: true,
  imports: [
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRange,
    IonTitle,
    IonToggle,
    IonToolbar,
  ],
  templateUrl: './machine-params-selection-modal.component.html',
  styleUrl: './machine-params-selection-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MachineParamsSelectionModalComponent implements OnChanges {
  @Input({ required: true }) machineType!: MachineTypeX;
  @Input() machineParamValues?: MachineParamValueDto[];
  @Output() appClose = new EventEmitter<void>();
  @Output() appSelect = new EventEmitter<MachineParamsAppSelectEvent>();

  protected machineParams: MachineParamUIData[] = [];

  constructor() {}

  ngOnChanges() {
    this.updateMachineParams();
  }

  onMachineParamsModalCloseBtnClick(ev: Event) {
    ev.preventDefault();
    this.appClose.emit();
  }

  onMachineParamsSaveBtnClick(ev: Event) {
    ev.preventDefault();
    const machineParams: MachineParamValueDto[] = [];
    for (const p of this.machineParams) {
      machineParams.push({
        paramName: p.name,
        boolValue: p.boolValue,
        rangeValue: p.rangeValue,
        selectNames: p.selectNames,
      });
    }
    const { txt, txtShort } = this.getParamsText();
    this.appSelect.emit({
      data: machineParams,
      txt,
      txtShort,
    });
  }

  onMachineParamRangeInput(ev: RangeCustomEvent, param: MachineParamUIData) {
    ev.preventDefault();
    if (typeof ev.detail.value === 'object') {
      param.rangeValue = [ev.detail.value.lower, ev.detail.value.upper];
    }
  }

  onMachineParamToggleChange(ev: ToggleCustomEvent, param: MachineParamUIData) {
    ev.preventDefault();
    param.boolValue = ev.detail.checked;
  }

  onMachineParamSelectChange(ev: CheckboxCustomEvent, param: MachineParamUIData) {
    ev.preventDefault();
    param.selectNames ??= [];
    if (ev.detail.checked) {
      param.selectNames.push(ev.detail.value);
    } else {
      const idx = param.selectNames.indexOf(ev.detail.value);
      if (idx > -1) {
        param.selectNames.splice(idx, 1);
      }
    }
  }

  private updateMachineParams() {
    this.machineParams = [];
    for (const param of this.machineType.params) {
      if (!param) {
        continue;
      }
      let val: MachineParamValueDto | undefined;
      for (const pval of this.machineParamValues ?? []) {
        if (pval.paramName === param.name) {
          val = structuredClone(pval); // to avoid edit by ref
          break;
        }
      }
      if (param.type === 'boolean') {
        this.machineParams.push({
          type: 'boolean',
          name: param.name,
          nameRu: param.nameRU,
          unit: param.unit ?? '',
          boolValue: val?.boolValue ?? false,
        });
      }
      if (param.type === 'range') {
        const min = param.settings.min ?? 0;
        const max = param.settings.max ?? 999999;
        this.machineParams.push({
          type: 'range',
          name: param.name,
          nameRu: param.nameRU,
          rangeMin: min,
          rangeMax: max,
          unit: param.unit ?? '',
          rangeValue: val?.rangeValue ?? [min, max],
        });
      }
      if (param.type === 'select') {
        this.machineParams.push({
          type: 'select',
          name: param.name,
          nameRu: param.nameRU,
          unit: param.unit ?? '',
          selectOption: param.settings.select.map((x: any) => x.name),
          selectNames: val?.selectNames ?? [],
        });
      }
    }
  }

  private getParamsText() {
    const texts: string[] = [];
    const textsShort: string[] = [];
    for (const machineParam of this.machineParams) {
      let txt = '';
      let txtShort = '';

      if (machineParam.type === 'boolean' && machineParam.boolValue === true) {
        txt = machineParam.nameRu;
        txtShort = machineParam.nameRu;
      }
      if (machineParam.type === 'range') {
        if (machineParam.rangeValue && isDefined(machineParam.rangeValue?.[0])) {
          txt += ` от ${machineParam.rangeValue[0]}`;
          if (
            isDefined(machineParam.rangeMin) &&
            machineParam.rangeValue[0] > machineParam.rangeMin
          ) {
            txtShort += ` от ${machineParam.rangeValue[0]}`;
          }
        }
        if (machineParam.rangeValue && isDefined(machineParam.rangeValue?.[1])) {
          txt += ` до ${machineParam.rangeValue[1]}`;
          if (
            isDefined(machineParam.rangeMax) &&
            machineParam.rangeValue[1] < machineParam.rangeMax
          ) {
            txtShort += ` до ${machineParam.rangeValue[1]}`;
          }
        }
        if (txt.length) {
          txt = `${machineParam.nameRu}: ${txt.trim()}`;
          if (machineParam.unit) {
            txt += ` ${machineParam.unit}`;
          }
        }
        if (txtShort.length && machineParam.unit) {
          txtShort += ` ${machineParam.unit}`;
        }
      }
      if (machineParam.type === 'select') {
        if (machineParam.selectNames?.length) {
          txt = `${machineParam.nameRu}: ${machineParam.selectNames.join(', ')}`;
          txtShort = `${machineParam.selectNames.join(', ')}`;
        }
      }
      if (txt) {
        texts.push(txt);
      }
      if (txtShort) {
        textsShort.push(txtShort);
      }
    }

    return { txt: texts.join(';\u00A0'), txtShort: textsShort.join(';\u00A0') };
  }
}
