<ion-header>
  <ion-toolbar>
    <ion-title>Договор</ion-title>
    <ion-buttons slot="start">
      <ion-button
        class="ion-margin-vertical"
        shape="round"
        size="medium"
        slot="end"
        (click)="onCloseBtnClick($event)"
      >
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-no-padding">
  <div class="spinner-wrapper">
    <ion-spinner name="lines-sharp"></ion-spinner>
  </div>
  <object #object></object>
</ion-content>
