<ion-header>
  <ion-toolbar>
    <ion-title>Характеристики</ion-title>
    <ion-buttons slot="start">
      <ion-button
        class="ion-margin-vertical"
        shape="round"
        size="medium"
        slot="end"
        (click)="onMachineParamsModalCloseBtnClick($event)"
      >
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list class="ion-padding">
    @for (param of machineParams; track param.name) {
      @if (param.type === "range" && param.rangeValue) {
        <ion-item class="ion-no-padding">
          <ion-range
            labelPlacement="stacked"
            [dualKnobs]="true"
            [min]="param.rangeMin"
            [max]="param.rangeMax"
            [value]="{ lower: param.rangeValue[0], upper: param.rangeValue[1] }"
            [pin]="true"
            (ionInput)="onMachineParamRangeInput($event, param)"
          >
            <div slot="label">
              <h4>{{ param.nameRu }}, {{ param.unit }}</h4>
            </div>
          </ion-range>
        </ion-item>
      }
      @if (param.type === "boolean") {
        <ion-item class="ion-no-padding rcitem">
          <ion-label class="boolean-label">{{ param.nameRu }}</ion-label>
          <ion-toggle
            class="param-toggle"
            slot="end"
            mode="ios"
            color="rcyellow"
            (ionChange)="onMachineParamToggleChange($event, param)"
            [checked]="param.boolValue"
          ></ion-toggle>
        </ion-item>
      }
      @if (param.type === "select" && param.selectNames) {
        <ion-item lines="none">{{ param.nameRu }}</ion-item>
        @for (name of param.selectOption; track $index) {
          <ion-item class="ion-no-padding">
            <ion-checkbox
              class="ion-padding-start"
              labelPlacement="end"
              justify="start"
              color="rcyellow"
              [value]="name"
              [checked]="param.selectNames.includes(name)"
              (ionChange)="onMachineParamSelectChange($event, param)"
            >
              {{ name }}
            </ion-checkbox>
          </ion-item>
        }
      }
    }
  </ion-list>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-button
    (click)="onMachineParamsSaveBtnClick($event)"
    class="ion-padding"
    expand="block"
    size="large"
    color="rcyellow"
  >
    Сохранить
  </ion-button>
</ion-footer>
