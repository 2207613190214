import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  Contact,
  Entry,
  EntryObjects,
  EntryParticipantsContacts,
  EntryProposes,
  EnumEntryParticipantsType,
  Maybe,
} from '../../../base-types.gql-gen';
import { callPhoneNumber, phoneFormatUniversal } from '../../utils';
import { AddressNewComponent } from '../../entry-details-machine/address-new/address-new';
import {
  IonIcon,
  IonSelect,
  IonSelectOption,
  ModalController,
  IonButton,
  IonAlert,
  IonInput,
  IonModal,
  IonButtons,
} from '@ionic/angular/standalone';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import * as uuidGen from 'uuid';

@Component({
  selector: 'app-entry-sign-address',
  templateUrl: './entry-sign-address.component.html',
  styleUrls: ['./entry-sign-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    IonIcon,
    IonSelect,
    IonSelectOption,
    FormsModule,
    IonButton,
    IonAlert,
    IonInput,
    IonModal,
    IonButtons,
  ],
})
export class EntrySignAddressComponent implements OnInit {
  @Input() myPropose?: Maybe<EntryProposes>;
  @Input() entry!: Entry;
  @Input() viewAs: EnumEntryParticipantsType = 'customer';
  @Output() selectContact = new EventEmitter();
  @ViewChild(IonModal) modal!: IonModal;

  selectedContact?: EntryParticipantsContacts;
  contact?: EntryParticipantsContacts;
  contacts: EntryParticipantsContacts[] = [];
  editFormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required, this.phoneValidator()]),
  });

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.setContacts();
    const phoneNumberCtrl = this.editFormGroup.get('phoneNumber');
    if (phoneNumberCtrl) {
      phoneNumberCtrl.valueChanges.forEach((number) => {
        phoneNumberCtrl.setValue(phoneFormatUniversal(number ?? ''), { emitEvent: false });
      });
    }
  }
  get customerParticipant() {
    return this.entry?.participants?.find((p) => p?.type === 'customer');
  }

  get objects(): Maybe<EntryObjects>[] {
    return this.myPropose?.objects || [];
  }

  get entryObjectContact(): EntryParticipantsContacts[] {
    return this.contacts || [];
  }
  phoneValidator() {
    return (control: AbstractControl): null | { [key: string]: any } => {
      const value = control.value?.replace(/\D/g, '');
      const isOk = /^7\d{10}$/.test(value);
      return isOk || !value ? null : { invalidPhoneNumber: { value: control.value } };
    };
  }
  setContacts() {
    const selectedContacts = this.customerParticipant?.contacts?.filter(Boolean) ?? [];
    const otherContacts = this.customerParticipant?.organization?.contacts?.filter(
      (c) => !selectedContacts.map((s) => s?.uuid).includes(c.uuid),
    );
    // @ts-ignore
    this.contacts.push(...selectedContacts, ...otherContacts);
  }

  contactForObject(object: EntryObjects): Maybe<EntryParticipantsContacts> | Contact | undefined {
    return (
      this.customerParticipant?.contacts?.find(
        (c) => !!c?.unions?.find((u) => u?.id === object.id || u?.uuid === object.uuid),
      ) || this.selectedContact
    );
  }

  getAddress(objects: EntryObjects): string {
    const txt = objects.title
      ? objects.title + ', ' + objects.address?.fullAddress || ''
      : objects.address?.fullAddress || '';
    return txt.replace('г Санкт-Петербург, ', '');
  }

  getContactItem(contact?: Maybe<EntryParticipantsContacts> | Contact | undefined) {
    const phone = contact?.contact;
    if (!phone) return '';
    return `${contact?.name}\n ${phoneFormatUniversal(contact?.contact?.value)}`;
  }
  getPhone(contact?: Maybe<EntryParticipantsContacts> | Contact | undefined) {
    return `${phoneFormatUniversal(contact?.contact?.value)}`;
  }
  onContactChange(contact?: any) {
    this.contact = contact;
    this.selectContact.emit(contact);
  }

  async openFullMap(object: EntryObjects) {
    const address = {
      full: object.address?.fullAddress,
      lat: object.address?.coordinates?.[0],
      lng: object.address?.coordinates?.[1],
      type: 'point',
    };
    const modal = await this.modalCtrl.create({
      component: AddressNewComponent,
      componentProps: {
        address,
        previousAddresses: [],
      },
    });
    await modal.present();
  }

  addContact() {
    if (this.editFormGroup.valid) {
      this.selectedContact = {
        uuid: uuidGen.v4(),
        name: this.editFormGroup.get('name')?.value,
        contact: {
          type: 'phone',
          value: this.editFormGroup.get('phoneNumber')?.value || '',
        },
        isDriver: false,
        unions: [],
      };
      this.contacts.push(this.selectedContact);
      this.onContactChange(this.selectedContact);
      this.modal.dismiss().then(() => this.editFormGroup.reset());
    } else {
      this.editFormGroup.markAllAsTouched();
    }
  }

  close() {
    this.modal.dismiss().then(() => this.editFormGroup.reset());
  }

  callToContact(contact?: Maybe<EntryParticipantsContacts> | Contact | undefined) {
    const phoneNumber = contact?.contact?.value;
    if (phoneNumber) {
      this.callTo(phoneNumber);
    }
  }

  callTo(phone: string) {
    let phoneString = `${phone ? phone : ''}`;
    if (phone.length === 7) {
      phoneString = phone;
    }
    callPhoneNumber(phoneString);
  }
}
