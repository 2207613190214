import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MachineTypeSelectionModalMachineTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MachineTypeSelectionModalMachineTypesQuery = { __typename?: 'Query', machineTypes?: Array<{ __typename?: 'MachineType', id: string, ref: string, nameRU: string, unit?: Array<string | null> | null, params: Array<{ __typename?: 'MachineTypeParams', ids?: any | null, name: string, nameRU: string, isRequired?: boolean | null, isEquipment?: boolean | null, type: Types.EnumMachineTypeParamsType, settings?: any | null, unit?: string | null, uuid: string, paramPurpose?: { __typename?: 'MachineTypeParamsParamPurpose', entry?: boolean | null, main?: boolean | null, usual?: boolean | null } | null } | null> } | null> | null };

export const MachineTypeSelectionModalMachineTypesDocument = gql`
    query MachineTypeSelectionModalMachineTypes {
  machineTypes {
    id
    ref
    nameRU
    unit
    params {
      ids
      name
      nameRU
      isRequired
      isEquipment
      type
      paramPurpose {
        entry
        main
        usual
      }
      settings
      unit
      uuid
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MachineTypeSelectionModalMachineTypesQueryGql extends Apollo.Query<MachineTypeSelectionModalMachineTypesQuery, MachineTypeSelectionModalMachineTypesQueryVariables> {
    override document = MachineTypeSelectionModalMachineTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }