import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { EntryRequestFormSimpleComponent } from '../entry-request-form-simple/entry-request-form-simple.component';
import { EntryRequestFormAuthCurrentUserQueryGql } from './entry-request-form.gql-gen';
import { EntryRequestFormDispatcherComponent } from '../entry-request-form-dispatcher/entry-request-form-dispatcher.component';

@Component({
  selector: 'app-entry-request-form',
  standalone: true,
  imports: [EntryRequestFormSimpleComponent, EntryRequestFormDispatcherComponent],
  templateUrl: './entry-request-form.component.html',
  styleUrl: './entry-request-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntryRequestFormComponent implements OnInit {
  protected entryRequestForm = '';

  constructor(
    private authCurrentUserQueryGql: EntryRequestFormAuthCurrentUserQueryGql,
    private cdRef: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    const res = await firstValueFrom(this.authCurrentUserQueryGql.fetch());
    this.entryRequestForm = 'simpleUser';
    for (const org of res.data?.authCurrentUser?.organizations ?? []) {
      for (const action of org?.availableActions ?? []) {
        if (action === 'CREATE_ENTRY_REQUEST_WITH_CUSTOMER') {
          this.entryRequestForm = 'dispatcher';
        }
        // if(action === "") {
        //   this.entryRequestForm = "specialCustomer";
        // }
      }
    }
    this.cdRef.detectChanges();
  }
}
