<div class="profile" tappable (click)="showCompany()">
  <div class="header-content">
    @if (company) {
      <div>
        @if (company) {
          <div class="company-name">
            <span>{{ company.name.substring(0, 50) }}</span>
            @if (editable) {
              <img class="edit-btn" src="assets/icon/edit.svg" alt="edit" />
            }
          </div>
        }
      </div>
    }
    @if (user) {
      <div class="profile-name">{{ user.firstname }} {{ user.lastname }}</div>
    }
  </div>
  @if (user) {
    <ion-avatar tappable class="profile-avatar" right>
      @if (user.avatar) {
        <img src="{{ user.avatar }}?w=300&h=300" />
      }
      @if (!user.avatar) {
        <img src="assets/avatar.svg" />
      }
    </ion-avatar>
  }
</div>

@if (user) {
  <div class="phone">
    <div class="contact">
      <div class="fast-info">
        <ion-icon slot="icon-only" name="call"></ion-icon>
      </div>
      <div class="contact-wrap">
        <span (click)="callTo(phoneFormat(user.phone))">{{ phoneFormat(user.phone) }}</span>
      </div>
    </div>
  </div>
}
