import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { getToken } from 'firebase/app-check';
import { appCheck } from '../firebase';
import { catchError, switchMap } from 'rxjs/operators';
import { appInfo } from '../app-info';

export function tokenInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const fireCheck = getToken(appCheck, false);
  return from(fireCheck).pipe(
    catchError((_err) => {
      // console.log(err);
      return of({ token: '' });
    }),
    switchMap((appCheckTokenRes) => {
      const authToken = localStorage.getItem('MOBAPP_V2_AUTH_TOKEN') ?? '';
      const tokenReq = req.clone({
        headers: req.headers
          .set('X-App', `${appInfo.name}@${appInfo.version}`)
          .set('X-Firebase-AppCheck', appCheckTokenRes.token)
          .set('Authorization', `Bearer ${authToken}`),
      });
      return next(tokenReq);
    }),
  );
}
