import type * as Types from '../../base-types.gql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type EntryRequestFormAuthCurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EntryRequestFormAuthCurrentUserQuery = { __typename?: 'Query', authCurrentUser?: { __typename?: 'User', id: string, organizations: Array<{ __typename?: 'Organization', availableActions?: Array<string | null> | null } | null> } | null };

export const EntryRequestFormAuthCurrentUserDocument = gql`
    query EntryRequestFormAuthCurrentUser {
  authCurrentUser {
    id
    organizations {
      availableActions
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntryRequestFormAuthCurrentUserQueryGql extends Apollo.Query<EntryRequestFormAuthCurrentUserQuery, EntryRequestFormAuthCurrentUserQueryVariables> {
    override document = EntryRequestFormAuthCurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }