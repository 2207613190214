import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { YaMapService } from '../ya-map.service';
import { assertIsDefined } from '../utils';

const MOSCOW_COORDS = [55.76, 37.64];

@Component({
  selector: 'app-preview-address-with-map-modal',
  standalone: true,
  imports: [IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon],
  templateUrl: './preview-address-with-map-modal.component.html',
  styleUrl: './preview-address-with-map-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewAddressWithMapModalComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input({ required: true }) coords: number[] = MOSCOW_COORDS;
  @Output() appClose = new EventEmitter<void>();
  @ViewChild('yaMapContainer') yaMapContainer?: ElementRef<HTMLDivElement>;

  private yaMap: any;
  private yaPlacemark: any;

  constructor(private yaMapSrv: YaMapService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('coords' in changes && this.yaMap && this.yaPlacemark) {
      this.updatePlacemarkCoords();
    }
  }

  async ngAfterViewInit() {
    await this.setupYaMap();
  }

  ngOnDestroy() {
    if (this.yaMap) {
      this.yaMap.destroy();
      this.yaPlacemark = undefined;
      this.yaMap = undefined;
    }
  }

  onCloseBtnClick(ev: Event) {
    ev.preventDefault();
    this.appClose.emit();
  }

  private async setupYaMap() {
    const ymaps = await this.yaMapSrv.ymaps;
    const div = this.yaMapContainer?.nativeElement;
    assertIsDefined(div);
    this.yaMap = new ymaps.Map(div, {
      center: MOSCOW_COORDS,
      controls: ['zoomControl'],
      zoom: 10,
    });
    this.yaPlacemark = new ymaps.Placemark(
      this.coords,
      {},
      {
        iconLayout: 'default#image',
        iconImageHref: './assets/icon/map-marker.svg',
      },
    );
    this.yaMap.geoObjects.add(this.yaPlacemark);
    this.updatePlacemarkCoords();
  }

  private updatePlacemarkCoords() {
    this.yaPlacemark.geometry.setCoordinates(this.coords);
    this.yaMap.setCenter(this.coords, 15);
  }
}
