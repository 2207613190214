import { getInstrumentationFlags, PAGE_LOAD_DELAY, PAGE_LOAD, ERROR, CONFIG_SERVICE, LOGGING_SERVICE, TRANSACTION_SERVICE, PERFORMANCE_MONITORING, ERROR_LOGGING, APM_SERVER, EVENT_TARGET, CLICK, observePageVisibility, observePageClicks, observeUserInteractions } from '@elastic/apm-rum-core';
var ApmBase = function () {
  function ApmBase(serviceFactory, disable) {
    this._disable = disable;
    this.serviceFactory = serviceFactory;
    this._initialized = false;
  }
  var _proto = ApmBase.prototype;
  _proto.isEnabled = function isEnabled() {
    return !this._disable;
  };
  _proto.isActive = function isActive() {
    var configService = this.serviceFactory.getService(CONFIG_SERVICE);
    return this.isEnabled() && this._initialized && configService.get('active');
  };
  _proto.init = function init(config) {
    var _this = this;
    if (this.isEnabled() && !this._initialized) {
      this._initialized = true;
      var _this$serviceFactory$ = this.serviceFactory.getService([CONFIG_SERVICE, LOGGING_SERVICE, TRANSACTION_SERVICE]),
        configService = _this$serviceFactory$[0],
        loggingService = _this$serviceFactory$[1],
        transactionService = _this$serviceFactory$[2];
      configService.setVersion('5.16.0');
      this.config(config);
      var logLevel = configService.get('logLevel');
      loggingService.setLevel(logLevel);
      var isConfigActive = configService.get('active');
      if (isConfigActive) {
        this.serviceFactory.init();
        var flags = getInstrumentationFlags(configService.get('instrument'), configService.get('disableInstrumentations'));
        var performanceMonitoring = this.serviceFactory.getService(PERFORMANCE_MONITORING);
        performanceMonitoring.init(flags);
        if (flags[ERROR]) {
          var errorLogging = this.serviceFactory.getService(ERROR_LOGGING);
          errorLogging.registerListeners();
        }
        if (configService.get('session')) {
          var localConfig = configService.getLocalConfig();
          if (localConfig && localConfig.session) {
            configService.setConfig({
              session: localConfig.session
            });
          }
        }
        var sendPageLoad = function sendPageLoad() {
          return flags[PAGE_LOAD] && _this._sendPageLoadMetrics();
        };
        if (configService.get('centralConfig')) {
          this.fetchCentralConfig().then(sendPageLoad);
        } else {
          sendPageLoad();
        }
        observePageVisibility(configService, transactionService);
        if (flags[EVENT_TARGET] && flags[CLICK]) {
          observePageClicks(transactionService);
        }
        observeUserInteractions();
      } else {
        this._disable = true;
        loggingService.warn('RUM agent is inactive');
      }
    }
    return this;
  };
  _proto.fetchCentralConfig = function fetchCentralConfig() {
    var _this$serviceFactory$2 = this.serviceFactory.getService([APM_SERVER, LOGGING_SERVICE, CONFIG_SERVICE]),
      apmServer = _this$serviceFactory$2[0],
      loggingService = _this$serviceFactory$2[1],
      configService = _this$serviceFactory$2[2];
    return apmServer.fetchConfig(configService.get('serviceName'), configService.get('environment')).then(function (config) {
      var transactionSampleRate = config['transaction_sample_rate'];
      if (transactionSampleRate) {
        transactionSampleRate = Number(transactionSampleRate);
        var _config2 = {
          transactionSampleRate: transactionSampleRate
        };
        var _configService$valida = configService.validate(_config2),
          invalid = _configService$valida.invalid;
        if (invalid.length === 0) {
          configService.setConfig(_config2);
        } else {
          var _invalid$ = invalid[0],
            key = _invalid$.key,
            value = _invalid$.value,
            allowed = _invalid$.allowed;
          loggingService.warn("invalid value \"" + value + "\" for " + key + ". Allowed: " + allowed + ".");
        }
      }
      return config;
    }).catch(function (error) {
      loggingService.warn('failed fetching config:', error);
    });
  };
  _proto._sendPageLoadMetrics = function _sendPageLoadMetrics() {
    var tr = this.startTransaction(undefined, PAGE_LOAD, {
      managed: true,
      canReuse: true
    });
    if (!tr) {
      return;
    }
    tr.addTask(PAGE_LOAD);
    var sendPageLoadMetrics = function sendPageLoadMetrics() {
      setTimeout(function () {
        return tr.removeTask(PAGE_LOAD);
      }, PAGE_LOAD_DELAY);
    };
    if (document.readyState === 'complete') {
      sendPageLoadMetrics();
    } else {
      window.addEventListener('load', sendPageLoadMetrics);
    }
  };
  _proto.observe = function observe(name, fn) {
    var configService = this.serviceFactory.getService(CONFIG_SERVICE);
    configService.events.observe(name, fn);
  };
  _proto.config = function config(_config) {
    var _this$serviceFactory$3 = this.serviceFactory.getService([CONFIG_SERVICE, LOGGING_SERVICE]),
      configService = _this$serviceFactory$3[0],
      loggingService = _this$serviceFactory$3[1];
    var _configService$valida2 = configService.validate(_config),
      missing = _configService$valida2.missing,
      invalid = _configService$valida2.invalid,
      unknown = _configService$valida2.unknown;
    if (unknown.length > 0) {
      var message = 'Unknown config options are specified for RUM agent: ' + unknown.join(', ');
      loggingService.warn(message);
    }
    if (missing.length === 0 && invalid.length === 0) {
      configService.setConfig(_config);
    } else {
      var separator = ', ';
      var _message = "RUM agent isn't correctly configured. ";
      if (missing.length > 0) {
        _message += missing.join(separator) + ' is missing';
        if (invalid.length > 0) {
          _message += separator;
        }
      }
      invalid.forEach(function (_ref, index) {
        var key = _ref.key,
          value = _ref.value,
          allowed = _ref.allowed;
        _message += key + " \"" + value + "\" contains invalid characters! (allowed: " + allowed + ")" + (index !== invalid.length - 1 ? separator : '');
      });
      loggingService.error(_message);
      configService.setConfig({
        active: false
      });
    }
  };
  _proto.setUserContext = function setUserContext(userContext) {
    var configService = this.serviceFactory.getService(CONFIG_SERVICE);
    configService.setUserContext(userContext);
  };
  _proto.setCustomContext = function setCustomContext(customContext) {
    var configService = this.serviceFactory.getService(CONFIG_SERVICE);
    configService.setCustomContext(customContext);
  };
  _proto.addLabels = function addLabels(labels) {
    var configService = this.serviceFactory.getService(CONFIG_SERVICE);
    configService.addLabels(labels);
  };
  _proto.setInitialPageLoadName = function setInitialPageLoadName(name) {
    var configService = this.serviceFactory.getService(CONFIG_SERVICE);
    configService.setConfig({
      pageLoadTransactionName: name
    });
  };
  _proto.startTransaction = function startTransaction(name, type, options) {
    if (this.isEnabled()) {
      var transactionService = this.serviceFactory.getService(TRANSACTION_SERVICE);
      return transactionService.startTransaction(name, type, options);
    }
  };
  _proto.startSpan = function startSpan(name, type, options) {
    if (this.isEnabled()) {
      var transactionService = this.serviceFactory.getService(TRANSACTION_SERVICE);
      return transactionService.startSpan(name, type, options);
    }
  };
  _proto.getCurrentTransaction = function getCurrentTransaction() {
    if (this.isEnabled()) {
      var transactionService = this.serviceFactory.getService(TRANSACTION_SERVICE);
      return transactionService.getCurrentTransaction();
    }
  };
  _proto.captureError = function captureError(error) {
    if (this.isEnabled()) {
      var errorLogging = this.serviceFactory.getService(ERROR_LOGGING);
      return errorLogging.logError(error);
    }
  };
  _proto.addFilter = function addFilter(fn) {
    var configService = this.serviceFactory.getService(CONFIG_SERVICE);
    configService.addFilter(fn);
  };
  return ApmBase;
}();
export { ApmBase as default };