<ion-header [class.invisible]="!selectedMachineType">
  <ion-toolbar>
    <ion-title>Аренда техники</ion-title>
    <ion-buttons slot="start">
      <ion-back-button [defaultHref]="'/home/entries'"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding no-border" [class.invisible]="!selectedMachineType">
  <ion-modal [isOpen]="isMachineTypeModalOpen" (didDismiss)="onMachineTypeModalDismiss($event)">
    <ng-template>
      <app-machine-type-selection-modal
        (rcSelect)="onMachineTypeModalRcSelect($event)"
        class="ion-page"
      ></app-machine-type-selection-modal>
    </ng-template>
  </ion-modal>
  @if (selectedMachineType) {
    <ion-input
      [readonly]="true"
      class="ion-margin-vertical"
      fill="outline"
      [value]="selectedMachineType.nameRU"
      (click)="onMachineTypeInputClick($event)"
    >
      <ion-icon slot="end" name="chevron-forward-outline" aria-hidden="true"></ion-icon>
    </ion-input>

    <!--    Характеристики   -->

    <ion-input
      (click)="onMachineParamsInputClick($event)"
      [readonly]="true"
      [value]="selectedMachineParamsText"
      class="ion-margin-vertical"
      fill="outline"
      placeholder="Характеристики (необязательно)"
    >
      <ion-icon slot="end" name="chevron-forward-outline" aria-hidden="true"></ion-icon>
    </ion-input>

    <ion-modal
      [isOpen]="isMachineParamsPickerOpen"
      (ionModalDidDismiss)="onMachineParamsModalDismiss($event)"
    >
      <ng-template>
        <app-machine-params-selection-modal
          [machineType]="selectedMachineType"
          [machineParamValues]="selectedMachineParams"
          (appClose)="onMachineParamsModalRcClose()"
          (appSelect)="onMachineParamsRcSelect($event)"
          class="ion-page"
        ></app-machine-params-selection-modal>
      </ng-template>
    </ion-modal>
  }

  <!--    Адрес   -->
  <div class="rc-address-list">
    @for (newAddressInput of addressObjects; track $index) {
      <div class="rc-suggest-list-wrapper">
        <div class="add-icon-after-address-input">
          <ion-input
            fill="outline"
            [placeholder]="newAddressInput.placeholder"
            [value]="newAddressInput.fullAddress"
            (ionFocus)="onAddressObjectInputFocus($event, $index)"
            (ionBlur)="onAddressObjectInputBlur($event)"
            (ionInput)="onAddressObjectInputInput($event)"
          >
            @if ($index > 0) {
              <ion-icon
                slot="end"
                name="close"
                aria-hidden="true"
                (click)="onRemoveAddressIconClick($event, newAddressInput)"
              ></ion-icon>
            }
          </ion-input>
          @if (newAddressInput.fullAddress) {
            <ion-icon
              color="primary"
              size="medium"
              class="large-pin-icon ion-padding-start"
              name="location"
              (click)="onPreviewAddressIconClick($event, newAddressInput.coords)"
            ></ion-icon>
          }
        </div>
        @if (focusedAddressObjectInputIdx === $index) {
          <div class="rc-suggest-list">
            <ion-list class="ion-padding">
              <ion-item-divider class="ion-no-padding">
                <ion-label>Действия</ion-label>
              </ion-item-divider>
              <ion-item
                (click)="onNewAddressObjectItemClick($event, newAddressInput)"
                class="ion-no-padding"
              >
                <ion-icon name="add-circle-outline" class="ion-padding-end"></ion-icon>
                Добавить новый адрес
              </ion-item>

              @for (group of addressObjectsSuggests; track $index) {
                @if (group.address.length) {
                  <ion-item-divider class="ion-no-padding">
                    <ion-label>{{ group.title }}</ion-label>
                  </ion-item-divider>
                  @for (addr of group.address; track $index) {
                    <ion-item
                      [button]="true"
                      [detail]="false"
                      (click)="onAddressObjectSuggestClick($event, addr, newAddressInput)"
                      class="ion-no-padding"
                    >
                      <div>
                        @if (addr.title) {
                          <strong>{{ addr.title }},&nbsp;</strong>
                        }
                        <span>{{ addr.fullAddress }}</span>
                      </div>
                    </ion-item>
                  }
                }
              }
              @if (isAddressObjectGroupsEmpty) {
                <ion-item lines="none">
                  <ion-text>Совпадений не найдено</ion-text>
                </ion-item>
              }
            </ion-list>
          </div>
        }
      </div>
    }
  </div>
  <ion-modal
    [isOpen]="previewAddressCoords.length > 0"
    (ionModalDidDismiss)="onPreviewAddressModalDismiss($event)"
  >
    <ng-template>
      <app-preview-address-with-map-modal
        [coords]="previewAddressCoords"
        (appClose)="onPreviewAddressModalCancel()"
      ></app-preview-address-with-map-modal>
    </ng-template>
  </ion-modal>
  <ion-modal
    [isOpen]="isAddressModalOpen"
    (ionModalDidDismiss)="onSearchAddressModalDismiss($event)"
  >
    <ng-template>
      <app-search-address-with-map-modal
        (appClose)="onSearchAddressModalClose()"
        (addressSelect)="onSearchAddressModalSelect($event)"
      ></app-search-address-with-map-modal>
    </ng-template>
  </ion-modal>
  @if (addressObjects.length < 5) {
    <ion-button
      (click)="onAddAddressObjectBtnClick($event)"
      [disabled]="!isFormAddressValid"
      class="ion-margin-top"
      fill="outline"
      size="small"
    >
      Еще адрес +
    </ion-button>
  } @else {
    <ion-button class="ion-margin-top" fill="outline" size="small">
      Больше не можем добавить 😔
    </ion-button>
  }

  <!--    Дата   -->

  <ion-input
    [readonly]="true"
    class="ion-margin-vertical"
    fill="outline"
    placeholder="Дата"
    (click)="onDateTimeInputClick($event)"
    [value]="selectedDateFormatted"
  >
    <ion-icon slot="end" name="calendar-outline" aria-hidden="true"></ion-icon>
  </ion-input>
  <ion-modal
    [isOpen]="isDateTineModalOpen"
    (ionModalDidDismiss)="onDateTimeModalDismiss($event)"
    class="datetime-wheel-modal"
  >
    <ng-template>
      <app-datetime-wheels-modal
        (rcDateTimeChange)="onRcDateTimeChange($event)"
      ></app-datetime-wheels-modal>
    </ng-template>
    <!--    <ng-template>-->
    <!--      <ion-content>-->
    <!--        <ion-datetime-->
    <!--          color="rcyellow"-->
    <!--          [value]="selectedDate"-->
    <!--          (ionChange)="onDateChange($event)"-->
    <!--          [disabled]="isDateFlexible"-->
    <!--          [showDefaultTitle]="true"-->
    <!--          presentation="date"-->
    <!--          locale="ru-RU-u-hc-h24"-->
    <!--          cancelText="Отмена"-->
    <!--          doneText="Выбрать"-->
    <!--          size="cover"-->
    <!--        >-->
    <!--          <span slot="time-label">Время</span>-->
    <!--          <span slot="title">Выберете дату работы</span>-->
    <!--        </ion-datetime>-->

    <!--        <ion-list>-->
    <!--          <ion-item lines="none">-->
    <!--            <ion-label>Выберете время работы</ion-label>-->
    <!--            <ion-datetime-button-->
    <!--              datetime="datetime"-->
    <!--              [disabled]="isTimeFlexible"-->
    <!--              class="date-padding-fixes"-->
    <!--            ></ion-datetime-button>-->

    <!--            <ion-popover [keepContentsMounted]="true" [showBackdrop]="true">-->
    <!--              <ng-template>-->
    <!--                <ion-datetime-->
    <!--                  (ionChange)="onTimeChange($event)"-->
    <!--                  [value]="selectedDate"-->
    <!--                  id="datetime"-->
    <!--                  locale="ru-RU-u-hc-h24"-->
    <!--                  presentation="time"-->
    <!--                ></ion-datetime>-->
    <!--              </ng-template>-->
    <!--            </ion-popover>-->
    <!--          </ion-item>-->
    <!--          <ion-item lines="none"></ion-item>-->
    <!--          <ion-item lines="none">-->
    <!--            <ion-label>Дата обсуждаема</ion-label>-->
    <!--            <ion-toggle-->
    <!--              mode="ios"-->
    <!--              color="rcyellow"-->
    <!--              [checked]="isDateFlexible"-->
    <!--              (ionChange)="onChangeDateFlexible($event)"-->
    <!--              slot="end"-->
    <!--              class="date-padding-fixes"-->
    <!--            ></ion-toggle>-->
    <!--          </ion-item>-->
    <!--          <ion-item lines="none">-->
    <!--            <ion-label>Время по договоренности</ion-label>-->
    <!--            <ion-toggle-->
    <!--              mode="ios"-->
    <!--              color="rcyellow"-->
    <!--              [checked]="isTimeFlexible"-->
    <!--              (ionChange)="onChangeTimeFlexible($event)"-->
    <!--              [disabled]="isDateFlexible"-->
    <!--              slot="end"-->
    <!--              class="date-padding-fixes"-->
    <!--            ></ion-toggle>-->
    <!--          </ion-item>-->
    <!--        </ion-list>-->
    <!--      </ion-content>-->
    <!--      <ion-footer class="ion-no-border">-->
    <!--        <ion-button-->
    <!--          (click)="onDatePickerSaveButton($event)"-->
    <!--          class="ion-padding"-->
    <!--          expand="block"-->
    <!--          size="large"-->
    <!--          color="rcyellow"-->
    <!--        >-->
    <!--          Готово-->
    <!--        </ion-button>-->
    <!--      </ion-footer>-->
    <!--    </ng-template>-->
  </ion-modal>

  <!--    Количество смен   -->

  @if (selectedWorkUnit) {
    <div class="unit-options ion-margin-vertical">
      @if (workUnits.length > 1) {
        <ion-select
          justify="space-between"
          fill="outline"
          [value]="selectedWorkUnit.name"
          (ionChange)="onWorkUnitSelectChange($event)"
          [interfaceOptions]="{ header: 'Объем работ' }"
          cancelText="Отмена"
          okText="Ок"
        >
          @for (unit of workUnits; track $index) {
            <ion-select-option [value]="unit.name">{{ unit.ruName }}</ion-select-option>
          }
        </ion-select>
      } @else {
        <ion-input fill="outline" [value]="workUnits[0].ruName" [readonly]="true"></ion-input>
      }
      <ion-input
        class="counter"
        (ionChange)="onWorkValueInputChange($event)"
        [value]="selectedWorkValue"
        fill="outline"
        inputmode="numeric"
      ></ion-input>
    </div>
  }

  <!--    Способ оплаты   -->
  <ion-select
    label="Способ оплаты"
    fill="outline"
    [value]="selectedPaymentType.value"
    (ionChange)="onPaymentSelectChange($event)"
    cancelText="Отмена"
    okText="Ок"
  >
    @for (paymentType of paymentTypes; track paymentType.value) {
      <ion-select-option [value]="paymentType.value">{{ paymentType.label }}</ion-select-option>
    }
  </ion-select>
  <ion-input
    (ionChange)="onPriceInputChange($event)"
    [disabled]="selectedPaymentType.value === 'none'"
    class="ion-margin-vertical"
    fill="outline"
    placeholder="Бюджет (необязательно)"
    inputmode="numeric"
    [maskito]="priceMask"
    [maskitoElement]="maskPredicate"
  >
    <ion-text slot="end">₽</ion-text>
  </ion-input>
  @if (selectedPaymentType.value === "none") {
    <ion-note>Для указания бюджета выберете способ оплаты</ion-note>
  }

  <!--    Комментарий   -->

  <ion-textarea
    (ionChange)="onCommentInputChange($event)"
    [autoGrow]="true"
    fill="outline"
    placeholder="Комментарий"
    class="ion-margin-vertical"
  ></ion-textarea>

  <ion-text>
    @if (this.providersCountTxt) {
      <span class="green-bold-text">{{ this.providersCountTxt }}</span>
      <span>&nbsp;в регионе</span>
    }
  </ion-text>
</ion-content>
<ion-footer appHideOnKeyboard class="ion-no-border" [class.invisible]="!selectedMachineType">
  <ion-button
    [disabled]="!isFormValid"
    (click)="onCreateEntryBtnClick($event)"
    class="ion-padding"
    expand="block"
    size="large"
    color="rcyellow"
  >
    Подобрать технику
  </ion-button>
</ion-footer>
